export const MapModule = {
    onUpdate: (dt) => {
    },

    onDraw: (dt) => {
    },

    onInitialized: () => {
    },

    onInitialStreamingCompleted: () => {
    }
};

export default MapModule;