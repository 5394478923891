export const season = {
    Spring: "Spring",
    Summer: "Summer",
    Autumn: "Autumn",
    Winter: "Winter"
};

export const time = {
	Dawn: "Dawn",
	Day: "Day",
	Dusk: "Dusk",
	Night: "Night"
};

export const weather = {
	Clear: "Default",
	Overcast: "Overcast",
	Foggy: "Foggy",
	Rainy: "Rainy",
	Snowy: "Snowy"
};
